<template>
  <el-table v-loading="loading" :data="operators">
    <el-table-column prop="short_name" label="Оператор" />
    <el-table-column label="Действия" align="right">
      <template slot-scope="scope">
        <el-button
          v-if="scope.row.id_operator === $props.data"
          size="small"
          type="primary"
          disabled
        >Назначен</el-button>
        <el-button
          v-else
          size="small"
          @click="setOperator(scope.row.id_operator)"
        >Назначить</el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "m2m-operators-table",
  props: {
    data: {
      type: Number,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    operators: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState("auth", ["token"]),
  },
  methods: {
    setOperator(id) {
      this.$emit("click", id);
    },
  }
};
</script>
