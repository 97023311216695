<template>
  <el-dialog
    title="IP-адрес для токена"
    :visible="visible"
    width="600px"
    @close="close"
  >
    <el-button
      id="openDialogAddM2MClientsIp"
      type="primary"
      size="small"
      icon="el-icon-plus"
      @click="visibleAdd = true"
    >Добавить</el-button>
    <el-table
      :data="ipAddresses"
      heigth="350"
    >
      <el-table-column prop="ip_address" label="IP-адрес" />
      <el-table-column prop="description" label="Описание" />
      <el-table-column label="Действия">
        <template slot-scope="scope">
          <el-button
            type="danger"
            size="small"
            @click="deleteIp(scope.row.ip_address)"
          >Удалить</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="Добавление IP-адреса"
      :visible.sync="visibleAdd"
      @close="visibleAdd = false"
      append-to-body
    >
      <el-form
        :model="form"
        :rules="rules"
        @submit="submit"
        ref="form"
      >
        <el-form-item
          prop="ipAddress"
          label="IP-адрес"
        >
          <el-input placeholder="IP-адрес" v-model="form.ipAddress" />
        </el-form-item>
        <el-form-item
          prop="description"
          label="Описание"
        >
          <el-input placeholder="Например, сервер Zabbix" v-model="form.description" />
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button
          type="primary"
          :loading="loading"
          native-type="submit"
          @click="submit"
        >Добавить</el-button>
      </span>
    </el-dialog>
  </el-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { required, validatorIp, length } from '@/core/helpers/validators'

export default {
  name: 'm2m-clients-ips-list',
  props: {
    visible: {
        type: Boolean,
        default: false,
    },
    item: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapState('m2m', [ 'list' ])
  },
  data () {
    return {
      visibleAdd: false,
      form: {
        ipAddress: '',
        description: '',
      },
      rules: {
        ipAddress: [
          required(),
          validatorIp
        ],
        description: [
          required(),
          length(5, 300)
        ],
      },
      loading: false,
      id: null,
      name: '',
      token: '',
      ipAddresses: [],
    };
  },
  watch: {
    visible () {
      this.id = this.$props.item.id;
      this.updateData();
    },
    list () {
      !this.id || this.updateData()
    },
  },
  methods: {
    submit(event) {
      event.preventDefault();

      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true;
          this.addM2MClientsIp({
            data: {
              ip_address: this.form.ipAddress,
              description: this.form.description
            },
            id: this.id
          })
            .then(() => {
              this.getList();
            })
            .then(() => {
              this.$message({
                message: 'IP-адрес добавлен',
                type: 'success'
              });
              this.updateData();
              this.visibleAdd = false;
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        }
      });
    },
    deleteIp (ipAddress) {
      this.$confirm('Удалить IP-адрес?', 'Удаление', {
        confirmButtonText: 'Удалить'
      })
        .then(() => {
          return this.removeM2mClientsIp({
            data: {
              ip_address: ipAddress
            },
            id: this.id
          });
        })
        .then(() => {
          this.getList();
        })
        .then(() => {
          this.$message({
            message: 'IP-адрес удален',
            type: 'success'
          });
          this.updateData();
        });
    },
    updateData () {
      const data = this.list.find((element) => { return element.id === this.id });
      this.name = data.name;
      this.token = data.token;
      this.ipAddresses = data.ip_addresses;
    },
    close () {
      this.$emit('update:visible', false);
      this.id = null;
      this.name = '';
      this.token = '';
      this.ipAddresses = [];
    },
    ...mapActions('m2m', {
      addM2MClientsIp: 'ADD_M2M_CLIENTS_IP',
      removeM2mClientsIp: 'REMOVE_M2M_CLIENTS_IP',
      getList: 'GET_LIST',
    })
  }
}
</script>

<style>
</style>
