<template>
  <el-container>
    <main class="page__container">
      <div class="container">
        <h2>M2M-клиенты </h2>
        <el-table v-loading="loading" :data="list">
          <el-table-column label="Имя" prop="name" />
          <el-table-column label="Токен" prop="token" />
          <el-table-column label="Оператор" prop="operator_name" />
          <el-table-column label="Действия">
            <template slot-scope="scope">
              <el-button
                size="small"
                @click="openM2MOperatorsList(scope.row)"
              >Оператор</el-button>
              <el-button
                size="small"
                @click="openListIps(scope.row)"
              >Список IP</el-button>
              <el-button
                type="danger"
                size="small"
                @click="deleteM2M(scope.row.id)"
              >Удалить</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-dialog
        title="Добавление"
        :visible.sync="visible"
        @close="close"
      >
        <el-input placeholder="Имя клиента" size="small" v-model="name" />
        <span slot="footer">
          <el-button
            id="addM2M"
            type="primary"
            size="small"
            icon="el-icon-plus"
            @click="createM2M"
          >Добавить</el-button>
        </span>
      </el-dialog>
      <m2m-clients-ips-list :visible.sync="visibleM2MClientsIpsList" :item="listItem" />
      <m2m-operators-list :visible.sync="visibleM2MOperatorsList" :item="listItem" @assign="assignM2MOperator" />
    </main>
    <el-footer class="page__active_bar">
      <el-button
        id="openDialogM2M"
        class="page__active_button"
        icon="el-icon-plus"
        type="primary"
        @click="visible = true"
      >Добавить</el-button>
    </el-footer>
  </el-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import M2MClientsIpsList from './M2MClientsIpsList'
import M2MOperatorsList from './M2MOperatorsList/M2MOperatorsList'

export default {
  name: 'm2m-list',
  components: {
    'm2m-clients-ips-list': M2MClientsIpsList,
    'm2m-operators-list': M2MOperatorsList
  },
  computed: {
    ...mapState('m2m', [ 'list', 'loading' ])
  },
  data () {
    return {
      name: '',
      visible: false,
      visibleM2MClientsIpsList: false,
      visibleM2MOperatorsList: false,
      listItem: null,
    }
  },
  mounted () {
    this.getList();
  },
  methods: {
    createM2M () {
      this.addM2M({ name: this.name })
        .then(() => {
          this.visible = false;
          this.$message({
            message: 'Клиент добавлен',
            type: 'success',
          });
          this.getList();
        })
        .catch(() => {
          this.visible = false;
        });
    },
    deleteM2M (id) {
      this.$confirm('Удалить M2M-клиента?', 'Удаление', {
        confirmButtonText: 'Удалить'
      })
        .then(() => {
          return this.removeM2M(id);
        })
        .then(() => {
          this.$message({
            message: 'Клиент удален',
            type: 'success',
          });
          this.getList();
        });
    },
    openListIps (item) {
      this.listItem = item;
      this.visibleM2MClientsIpsList = true;
    },
    openM2MOperatorsList (item) {
      this.listItem = item;
      this.visibleM2MOperatorsList = true;
    },
    assignM2MOperator () {
      this.getList();
    },
    close () {
      this.name = '';
    },
    ...mapActions('m2m', {
      getList: 'GET_LIST',
      addM2M: 'ADD_M2M',
      removeM2M: 'REMOVE_M2M',
    })
  }
}
</script>

<style>
</style>
