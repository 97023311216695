export default {
  computed: {
    countPages() {
      return Math.ceil(this.total / this.count);
    },
  },
  data() {
    return {
      page: 1,
    };
  },
  mounted() {
    this.query = this.getQuery;
    this.loadData();
  },
  methods: {
    search() {
      this.changePage(1);
      this.setQuery(this.query);
    },
    changePage(page) {
      this.page = page;
      this.loadData();
    },
    loadData() {
      this.getList({
        query: this.query,
        page: this.page,
        count: this.count,
      });
    },
  },
};
