<template>
  <el-dialog
    title="Оператор для токена"
    :visible="visible"
    width="600px"
    @close="close"
  >
    <search :query.sync="query" @search="search" clearable />
    <m2m-operators-table :data="current.id_operator" :operators="items" :loading="loading" @click="assign" />
    <el-pagination
      class="list__pagination"
      layout="prev, pager, next"
      :page-size="count"
      :total="total"
      :current-page="page"
      @current-change="changePage"
    />
  </el-dialog>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import M2MOperatorsTable from "./M2MOperatorsTable.vue";
import Search from "@/components/Search";
import commonList from "@/core/mixins/commonList";

export default {
  name: "m2m-operators-list",
  components: {
    "m2m-operators-table": M2MOperatorsTable,
    Search,
  },
  mixins: [commonList],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      query: "",
    };
  },
  computed: {
    current() {
      return this.visible ? (this.items.find(item => item.id_operator === this.$props.item.id_operator) || {}) : {};
    },
    ...mapState("m2m", {
      items: (state) => state.operators.operators.data,
      total: (state) => state.operators.operators.total,
      count: (state) => state.operators.operators.count,
      loading: (state) => state.operators.operators.loading,
    }),
    ...mapGetters("m2m", {
      getQuery: "getQueryOperators"
    }),
  },
  methods: {
    close () {
      this.$emit("update:visible", false);
    },
    assign (id) {
      this.addM2MOperator({
        data: {
          id_operator: id
        },
        id: this.$props.item.id
      })
        .then(() => {
          this.$emit("assign", id);
          this.$message({
            message: 'Оператор назначен',
            type: 'success'
          });
          this.close();
        })
        .catch((e) => {
          this.$message({
            type: "error",
            message: e.response.data.error || "Неизвестная ошибка",
          });
        });
    },
    ...mapActions("m2m", {
      addM2MOperator: 'ADD_M2M_OPERATOR',
      getList: "GET_OPERATORS",
    }),
    ...mapMutations("m2m", {
      setQuery: "SET_QUERY_OPERATORS",
      clearList: "SET_LIST_OPERATORS",
    }),
  },
};
</script>

<style lang="scss">
.list,
.list__pagination {
  margin: 20px 0;
  text-align: center;
}
.el-table th > .cell {
  word-break: break-word;
}
</style>
